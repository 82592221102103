<template>
    <div id="market-overview" class="widget-wrapper">
        <div class="tradingview-widget-container">
            <div class="tradingview-widget-container__widget"></div>
        </div>
        <div id="script-container-market-overview" class="widget-script-container"></div>
    </div>
</template>

<script>
const SCRIPT_ID = 'tradingview-widget-script';
const CONTAINER_ID = 'vue-trading-view';

export default {
    name: 'VueTradingView',
    data() {
        return {
            container_id: CONTAINER_ID,
        };
    },
    props: {
        options: Object,
    },
    methods: {
        canUseDOM() {
            return (
                typeof window !== 'undefined' &&
                window.document &&
                window.document.createElement
            );
        },
        getScriptElement() {
            return document.getElementById(SCRIPT_ID);
        },
        updateOnloadListener(onload) {
            const script = this.getScriptElement();
            const oldOnload = script.onload;
            return (script.onload = () => {
                oldOnload();
                onload();
            });
        },
        scriptExists() {
            return this.getScriptElement() !== null;
        },
        appendScript(onload) {
            var text = document.createTextNode(
                '{ "colorTheme": "dark",  "isTransparent": false,  "width": "100%",  "height": "100%",  "locale": "en",  "importanceFilter": "-1,0,1"}'
            );
            const script = document.createElement('script');
            script.id = SCRIPT_ID;
            script.type = 'text/javascript';
            script.appendChild(text);

            script.src =
                'https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js';
            script.async = true;
            script.onload = onload;
            //console.log(script);
            document
                .getElementById('script-container-market-overview')
                .appendChild(script);
        },
    },
    mounted() {
        this.appendScript(this.initWidget);
    },
};
</script>
<style>
  #market-overview {

  }


  #tradingview-widget-script {

  }

  #script-container-market-overview iframe  {

  }

  #script-container-market-overview iframe .tv-embed-widget-wrapper__body  {
    background: #000000;
  }

  .js-embed-widget-body {

  }

  .tv-embed-widget-wrapper__body {

  }


</style>
